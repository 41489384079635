import React, { useState } from 'react';
import axios from 'axios';
import Logo from '../../../logo.png';

const ForgotPassword = () => {
  console.log('deploy was succesful a fourth time with an env');
  const [responseMessage, setResponseMessage] = useState(null);

  const [formData, setFormData] = useState({
    username: ''
  });

    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${process.env.REACT_APP_ROADONE_API_FORGOT_PASSWORD_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&cpEmailAddress=${formData.username}`);
        // Handle success (e.g., store the token in local storage and redirect to the dashboard)
        console.log(response);  
        console.log(JSON.stringify(response.data.value[0]).response);
          if (response.data.value[0].response == 1) {
              setResponseMessage(response.data.value[0].responsemessage)
          }
          else {
            await axios.post(`${process.env.REACT_APP_ROADONE_API_LOG_ENTRY_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ulEmailAddress=${formData.username}&ulLogType=2&ulNote=login not succesful`);
            setResponseMessage(response.data.value[0].responsemessage ? response.data.value[0].responsemessage : 'error, try again later');
          }
  
      } catch (error) {
        // Handle error (e.g., show an error message)
      }
    };


  return (
    <div className="login">
        <img src={Logo} alt='RoadOne Logo' />
        <form>
          <p>Forgot Password:</p>
          <input type="text" name="username" placeholder='Email' autoComplete='username' value={formData.username} onChange={handleChange} />
          {/* <a href="">Forgot Password?</a> */}
          <button type="submit" onClick={handleSubmit}>Send Email</button>
          <p className='login-error'>{responseMessage}</p>
        </form>
    </div>
  );
};

export default ForgotPassword;
