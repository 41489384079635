import React, { useState } from 'react';
import axios from 'axios';
import Logo from '../../../logo.png';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink
} from 'react-router-dom';

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [responseMessage, setResponseMessage] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_ROADONE_API_LOGIN_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&cpEmailAddress=${formData.username}&cpPassword=${formData.password}`);
      // Handle success
      console.log(response);  
      console.log(JSON.stringify(response.data.value[0]).response);
        if (response.data.value[0].response == 1) {
            localStorage.setItem('token', JSON.stringify(response.data.value[0]));
            localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_ROADONE_API_LOG_ENTRY_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ulEmailAddress=${formData.username}&ulLogType=1&ulNote=login succesful`);
            window.open('/', '_self');
        }
        else {
          await axios.post(`${process.env.REACT_APP_ROADONE_API_LOG_ENTRY_URL}?@AuthToken=9n0U7r3c3E3t1r3A0t0b&ulEmailAddress=${formData.username}&ulLogType=2&ulNote=login not succesful`);
          setResponseMessage(response.data.value[0].responsemessage ? response.data.value[0].responsemessage : 'error, try again later');
        }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login">
        <img src={Logo} alt="RoadOne logo" />
        <form onSubmit={handleSubmit}>
          <p>Customer Portal:</p>
          <input type="text" name="username" placeholder='Email' value={formData.username} onChange={handleChange} autoComplete="username" />
          <input type="password" name="password" placeholder='Password' value={formData.password} onChange={handleChange} autoComplete="password" />
          <button type="submit">Log in</button>
          <NavLink className="login-forgot-password" to='/forgotPassword'>Forgot Password?</NavLink>
          <p className='login-error'>{responseMessage ? responseMessage : ''}</p>
        </form>
    </div>
  );
};

export default Login;
