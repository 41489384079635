import React, { Component } from 'react';
import DateFilter from './dateFilter';
import OpenOrders from './openOrders';
// import API from '../../api/api'

class Home extends Component {

  render() {
    return (
    <nav className='home'>
      <h3>{JSON.parse(localStorage.getItem('token')).groupname}: Open Orders</h3>
      <p>User: {JSON.parse(localStorage.getItem('token')).username}</p>
      <OpenOrders />
      {/* <API /> */}
    </nav>
    );
  }
}

export default Home;