import React, { Component } from 'react';
import DateFilter from './dateFilter';
import OpenOrdersHistory from './openOrdersHistory';
// import API from '../../api/api'

class OrderHistory extends Component {
  render() {
    return (
    <nav className='home'>
      <h3>{JSON.parse(localStorage.getItem('token')).groupname}: Order History</h3>
      <p>User: {JSON.parse(localStorage.getItem('token')).username}</p>
      <OpenOrdersHistory />
      {/* <API /> */}
    </nav>
    );
  }
}

export default OrderHistory;